var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(34, 34, 34, 0.27)","overlay-opacity":"0.8","max-width":"520","persistent":""},on:{"click:outside":_vm.close},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[(_vm.dialogLoading)?_c('SkeletonPreloader',{attrs:{"count":1,"type":'list-item-two-line',"width":'100%',"height":'13vh'}}):[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addContact)}}},[_c('v-card-title',{staticClass:"black--text ft-light text-md"},[_vm._v("Create Contact "),_c('v-btn',{staticClass:"add-payment close-icon",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("close")])])],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("people")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"autofocus":"","hint":"Provide the name of your contact here..","label":"Full Name","persistent-hint":true},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|numeric|min:10|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full flex-grow-1 mt-1 mb-2"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"hint":"Phone Number","label":"Phone Number","persistent-hint":true},model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}})],1)]}}],null,true)}),_c('ValidationProvider',[_c('div',{staticClass:"d-flex flex-row flex-full flex-grow-1 mt-3"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',{staticClass:"material-icons-outlined mr-4 mt-3"},[_vm._v("event")]),_c('v-text-field',_vm._g(_vm._b({staticClass:"ft font-weight-medium font-size-sm",attrs:{"label":"Date Of Birth","prepend-icon":"","clearable":"","readonly":""},model:{value:(_vm.contact.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.contact, "dateOfBirth", $$v)},expression:"contact.dateOfBirth"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{staticClass:"ft font-weight-medium font-size-sm",on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.contact.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.contact, "dateOfBirth", $$v)},expression:"contact.dateOfBirth"}})],1)],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-sm ft text-capitalize",attrs:{"text":"","color":"accent"},on:{"click":_vm.close}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium text-sm text-capitalize",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading,"color":"primary"}},[_vm._v("Save")])],1)],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }